<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row py-3 px-4" id="filter-card">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="filterResult">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 my-2 row px-0 mx-0">
                <input-filter
                    :helper-text="$t('general.search')"
                    title="general.search"
                    :placeholder="$t('general.search_filter')"
                    :model.sync="search"
                ></input-filter>
              </div>
              <div class="col-lg-4 col-md-5 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter :helper-text="$t('general.start_date')" title="general.start_date"
                    :model.sync="start_date" name="start_time">
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter :helper-text="$t('general.end_date')" title="general.end_date"
                    :model.sync="end_date" name="end_date">
                  </date-filter>
                </div>
              </div>
              <div
                class="col-xl-1 col-lg-2 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important;">
                <div class="col-12 pr-0">
                  <button type="button" @click="filterResult()"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1">{{
                    $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button type="button" @click="resetFilters"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                    {{ $t('general.clear') }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <dashboard-box class="pb-10">
        <template v-slot:preview>
          <data-table
              class="relative"
              tableVariant="''"
              :hover="false"
              :outlined="false"
              :striped="false"
              :no-border-collapse="true"
              :borderless="true"
              :bordered="false"
              @onChangeStatus="onChangeStatus"
              @onClickDelete="onClickDelete"
              @onClickEdit="onClickEdit"
              @hitBottom="onHitBottom"
              :items="formattedItem"
              :fields="fields"
              :per-page.sync="per_page"
              :page.sync="page"
              :infiniteId="infiniteId"
          ></data-table>
          <div class="d-flex justify-content-end items-end fixed cursor-pointer" style="right: 10px; bottom: 10px;">
            <div class="w-60px h-60px"
                 @click="$router.push({name: createRouteName})">
              <span v-html="getIconByKey('icons.waybill.waybill_entry', {
                  class: 'w-60px h-60px d-inline-block object-fill cursor-pointer'
                })">
                </span>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import DataTable from "@/assets/components/dataTable/DataTable";
import moment from 'moment';
import {LARAVEL_DATE_TIME_FORMAT, MOMENT_SYSTEM_DATE_FORMAT, LARAVEL_DATE_FORMAT} from "@/core/config/constant";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";

import baseModule, {
  BASE_URL,
  ERROR,
  ITEMS,
  LOADING,
  MODULE_NAME,
  DELETE_ITEM,
  SUCCESS,
  UPDATE_ITEM,
  SET_ITEMS,
  HANDLE_INFINITE_SCROLL
} from "@/core/services/store/transferred-invoice/transferredInvoice.module";
import store from "@/core/services/store";

const _MODULE_NAME = MODULE_NAME;

export default {
  name: "TransferredInvoice",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }

    registerStoreModule(_MODULE_NAME, baseModule)
  },
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    DateFilter
  },
  computed: {
    error() {
      return store.getters[_MODULE_NAME + '/' + ERROR];
    },
    loading() {
      return store.getters[_MODULE_NAME + '/' + LOADING];
    },
    success() {
      return store.getters[_MODULE_NAME + '/' + SUCCESS];
    },
    items() {
      return store.getters[_MODULE_NAME + '/' + ITEMS];
    },
    filters() {
      return {
        per_page: this.per_page,
        page: this.page,
        search: this.search,
        start_date:moment(this.start_date).format(LARAVEL_DATE_FORMAT),
        end_date:moment(this.end_date).format(LARAVEL_DATE_FORMAT),
      }
    },
    formattedItem() {
      let results = [];

      if (this.items === null || this.items.data === null || this.items.data === undefined) return [];
      let items = this.items.data;

      items.forEach(currentItem => {
        let createdAt = currentItem.created_at ? moment(currentItem.created_at, LARAVEL_DATE_TIME_FORMAT) : moment();
        results.push({
          id: currentItem.id,
          name: currentItem.docnr,
          createdAt: createdAt.format(MOMENT_SYSTEM_DATE_FORMAT),
        })
      })

      return results;
    }
  },
  data() {
    return {
      start_date: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
      end_date: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
      page: 1,
      per_page: 25,
      infiniteId: 1,
      search: '',
      state: null,
      languages: [],
      fields: [
        {
          key: 'id',
          label: this.$t('general.id'),
          sortable: true,
          class: 'text-center border border-top-0 border-right-0 font-size-lg'
        },
        {
          key: 'name',
          label: this.$t('invoice.invoice_number'),
          sortable: true,
          class: 'text-center border border-top-0 border-right-0 font-size-lg'
        },
        {
          key: 'createdAt',
          label: this.$t('general.created_at'),
          sortable: false,
          class: 'text-center border border-top-0 border-right-0 font-size-lg'
        },
        {
          key: 'only_delete',
          label: this.$t('general.actions'),
          sortable: false,
          class: 'text-center border border-top-0 border-right-0 font-size-lg'
        },
      ],
    };
  },
  methods: {
    onChangeStatus(id) {
      let self = this;
      let item = this.items.data.find(item => item.id === id);
      item.status = !item.status;

      let content = {
        name : item.name,
        email : item.email,
        status : item.status,
      }
      this.$store.dispatch(_MODULE_NAME + '/' + UPDATE_ITEM, {
        filters: this.filters,
        url: BASE_URL + '/' + id,
        contents: content
      });
    },
    onHitBottom($state){
      if(this.loading) {
        return;
      }
      if ($state) {
        this.state = $state;
      }
      this.$store.dispatch(_MODULE_NAME + '/' + HANDLE_INFINITE_SCROLL, {
        filters: this.filters,
        url: BASE_URL,
        '$state': $state,
      }).then(result => {
        if(result.status && $state) {
          $state.loaded();
        }else{
          if('end' in result && $state){
            $state.complete();
          }
        }
      })
    },
    onClickDelete(id) {
      let self = this;
      this.sweetAlertConfirm(this.$t("general.are_you_sure")).then(result => {
        if(result) {
          let payload = {
            url: BASE_URL + '/' + id,
            id: id,
          }
          self.$store.dispatch(_MODULE_NAME + '/' + DELETE_ITEM, payload)
        }
      })
    },
    filterResult() {
      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onHitBottom();

      if (self.state) {
        setTimeout(() => {
          self.state.reset();
        }, 100);
      }
    },
    resetFilters() {
      this.search = '';
      this.language_id = null;
      this.start_date= moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT);
      this.end_date= moment().add(1, 'd').format(LARAVEL_DATE_FORMAT);
      this.filterResult()
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.dashboard"), route: "dashboard"},
      {title: this.$t("transferred_invoices.name")}
    ]);

    this.$store.commit(_MODULE_NAME + '/' + SET_ITEMS, null);

    setTimeout(this.filterResult, 1000);
  }
}
</script>

<style scoped>

</style>
